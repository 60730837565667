<template>
  <div class="grey lighten-4" style="height: calc(100vh - 88px)">
    <v-container fluid class="mb-3">
      <v-row align="center">
        <v-col cols="12" class="mb-3 pb-0">
          <v-card elevation="2" rounded="0" class="mb-1" color="blue lighten-5">
            <v-card-text>
              <v-row align="center">
                <v-col cols="1" class="text-left">
                  <v-icon x-large :title="tLabel('Back')" @click="goToTruckInventoryVisits">mdi-arrow-left</v-icon>
                </v-col>
                <v-col cols="2" class="text-h5 text-uppercase font-weight-light" v-if="truck">
                  {{ truck.objectCode }}
                </v-col>
                <v-col cols="8" class="text-h6 text-uppercase font-weight-light" v-if="truck">
                  <!-- {{ truck.hinDriver }}  -->
                  {{ truck.owner }} {{ truck.positionCode }} 
                  <!-- {{ truck.hinOrderCode }}  -->
                  {{ truck.dataIn && truck.dataIn !== null ? "IN:" : "" }} {{ truck.dataIn | formatDateTimeFromDate }}
                  {{ truck.dataOut && truck.dataOut !== null ? "OUT:" : "" }} {{ truck.dataOut | formatDateTimeFromDate }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- {{ truck }} -->
      <v-row align="center">
        <v-col cols="12" class="mt-0 pt-0 scroller" style="height: calc(100vh - 188px)">
          <DropoffPanel :truckVisitId="truckVisitId" :readOnly="readOnly" :addEnabled="!readOnly" :removeEnabled="!readOnly" />
          <PickupPanel :truckVisitId="truckVisitId" :readOnly="readOnly" :addEnabled="!readOnly" :removeEnabled="!readOnly" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import DropoffPanel from "@/components/yard/DropoffPanel.vue";
import PickupPanel from "@/components/yard/PickupPanel.vue";
import FormatsMixins from "../../../mixins/FormatsMixins";

export default {
  name: "EditTransits",
  data() {
    return {
      truck: null,
    };
  },
  props: {
    truckVisitId: {
      type: String,
      required: true,
    },
  },
  components: { DropoffPanel, PickupPanel },
  mixins: [FormatsMixins],
  async mounted() {
    this.truck = await this.$api.yardData.getRoroVisitById(this.truckVisitId);
  },
  computed: {
    readOnly() {
      return this.truck && this.truck.dataOut && this.truck.dataOut !== null;
    }
  },
  methods: {
    goToTruckInventoryVisits() {
      this.$router.push({ path: "/yard/inventory/truckInventoryVisits" });
    },
  },
};
</script>

<style>
.scroller {
  overflow-y: auto;
  scrollbar-width: thin;
}

.scroller::-webkit-scrollbar {
  width: 6px;
}

.scroller::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scroller::-webkit-scrollbar-thumb {
  background: #888;
}

.scroller::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
